import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import React from 'react';
import PrivacyArea from './privacyPolicyPage.style';
const PrivacyPolicyPage = () => {
    return (
        <PrivacyArea id="PrivacyPolicySection">
            <Container>
            <Heading as="h4" content="POLÍTICA DE PRIVACIDAD" className="font-" />
            <Box className="mt-12 text-left">
                <div className="col-sm-12">
                    <p><strong>Última actualització: 21/10/2022</strong></p>
                    <p></p>
                    <p className="mt-12 mb-10"><strong>INFORMACIÓ</strong></p>
                    <p></p>
                    <p>De conformitat amb la legislació europea i espanyola i, en concret amb la Llei 34/2002, de l'11
                        de juliol, de Serveis de la Societat de la Informació i Comerç Electrònic (en endavant, la
                        “<strong>LSSI-CE</strong>”), li informem que el lloc web <a
                            href="https://info.sirventproductes.com">https://www.sirventproductes.com</a> (en endavant,
                        el “<strong>Web</strong>”) és propietat de Sirvent Productes, amb domicili social a&nbsp;Carrer
                        Camí Antic de Vic, 17, 08520 Corró d'Avall, Barcelona<u><strong>&nbsp;</strong></u>i&nbsp;C.I.F.<b><u>&nbsp;</u>B60730256</b>
                    </p>
                    <p></p>
                    <p>Per a Sirvent Productes, respectar la seva privacitat i complir amb la normativa de protecció de
                        dades és un aspecte de vital importància.</p>
                    <p></p>
                    <p>El nostre desig és que en tot moment conegui per què utilitzem o volem utilitzar les seves dades
                        i quins són els seus drets. Amb aquest objectiu, hem redactat la següent Política de Privacitat
                        (en endavant, la “<strong>Política de Privacitat</strong>”) que regula el tractament de dades
                        que es durà a terme quan qualsevol Usuari navegui pel nostre web.</p>
                    <p></p>
                    <p className="mt-12 mb-10"><strong>1. Qui és el responsable del tractament de les seves dades?</strong></p>
                    <p></p>
                    <p>En els termes de la normativa de protecció de dades aplicable i, especialment, del 'Reglament
                        (UE) 2016/679, del Parlament Europeu i de Consell, del 27 d'abril de 2016, relatiu a la
                        protecció de les persones físiques pel que fa al tractament de dades personals i a la lliure
                        circulació d'aquestes dades (des d'ara, l' “<strong>RGPD</strong>”) i a la Llei Orgànica 3/2018,
                        del 5 de desembre, de Protecció de Dades Personals i de garantia dels drets digitals (en
                        endavant, la “<strong>LOPDGDD</strong>”), el Responsable del Tractament és:</p>
                    <p></p>
                    <p><strong>Responsable del Tractament:</strong>&nbsp;Sirvent Productes</p>
                    <p><strong>C.I.F.:</strong>&nbsp;B60730256</p>
                    <p><strong>Adreça: Carrer Camí Antic de Vic, 17, 08520 Corró d'Avall, Barcelona</strong></p>
                    <p><strong>Telèfon:</strong> 93 849 87 18 </p>
                    <p><strong>Correu electrònic:&nbsp;</strong><a
                        href="info@sirventproductes.com">info@sirventproductes.com</a></p>
                    <p></p>
                    <p className="mt-12 mb-10"><strong>2. Quins principis aplicarem sobre la informació personal?</strong></p>
                    <p></p>
                    <p>En el tractament de les seves dades de caràcter personal, aplicarem els següents principis, que
                        s'ajusten a les exigències de l'RGPD:</p>
                    <p></p>
                    <ul>
                        <li><strong>Principi de licitud, lleialtat i transparència:</strong>&nbsp;Sempre requerirem el
                            seu consentiment pel tractament de les seves dades personals per a un o diversos fins
                            específics que l'informarem prèviament amb absoluta transparència.
                        </li>
                    </ul>
                    <p className="margin-left: 36px"></p>
                    <ul>
                        <li><strong>Principi de minimització de dades:</strong>&nbsp;Només li sol·licitarem les dades
                            estrictament necessàries en relació amb els fins pels quals els requerim.
                        </li>
                    </ul>
                    <p className="margin-left: 36px"></p>
                    <ul>
                        <li><strong>Principi de limitació del termini de conservació</strong>:&nbsp;Les dades seran
                            mantingudes durant no més temps del necessari pels fins del tractament, en funció a la
                            finalitat, i l'informarem del termini de conservació corresponent.
                        </li>
                    </ul>
                    <p className="margin-left:36px;"></p>
                    <ul>
                        <li><strong>Principi d’integritat i confidencialitat:</strong>&nbsp;Les seves dades seran
                            tractades de tal manera que es garanteixi una seguretat adequada de les dades personals i es
                            garanteixi la confidencialitat. Ha de saber que prenem totes les precaucions necessàries per
                            evitar l'accés no autoritzat o ús indegut de les dades dels nostres usuaris per part de
                            tercers.
                        </li>
                    </ul>
                    <p></p>
                    <p className="margin-left:36px;"></p>
                    <p className="mt-12 mb-10"><strong>3. Quines són les categories de dades tractades i com s'ha procedit a la seva
                        obtenció?</strong></p>
                    <p></p>
                    <p>Les categories de dades personals que Sirvent Productes tracta sobre els seus clients són:</p>
                    <p>• Dades d'identificació personal.</p>
                    <p></p>
                    <p>Les dades anteriorment esmentades han estat obtingudes:</p>
                    <p>• Directament de l'interessat, a través d'un formulari de contacte disponible a la web.</p>
                    <p className="margin-left:35px;">• Directament de l'interessat, al facilitar-nos les dades
                        d'identificació necessàries per dur a terme l'objecte de la relació contractual. Li correspon a
                        l'interessat assegurar l'actualització de les dades facilitades en cas de modificació.</p>
                    <p></p>
                    <p className="mt-12 mb-10"><strong>4. Per a què es tractaran les seves dades i per què?</strong></p>
                    <p>Sirvent Productes tractarà les dades personals de l'Usuari, de manera manual i/o automatitzada,
                        per a les següents finalitats:</p>
                    <p></p>
                    <table border="1" cellPadding="0" cellSpacing="0">
                        <tbody>
                        <tr>
                            <td className="width:160px;">
                                <p align="center"><strong>Tractament de dades</strong></p>
                            </td>
                            <td className="width:208px;">
                                <p align="center"><strong>Finalitat del tractament</strong></p>
                            </td>
                            <td className="width:198px;">
                                <p align="center"><strong>Base jurídica del tractament</strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td className="width:160px;">
                                <p><strong>Resolució de consultes</strong></p>
                            </td>
                            <td className="width:208px;">
                                <p>Donar solució a les consultes d'informació, queixes o reclamacions plantejades a
                                    través del formulari de contacte habilitat per a això a la web.</p>
                            </td>
                            <td className="width:198px;">
                                <p>Consentiment de l’interessat.</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="width:160px;">
                                <p><strong>Execució d’un contracte d’encàrrecs online</strong></p>
                            </td>
                            <td className="width:208px;">
                                <p>Gestió dels encàrrecs en línia de productes amb o sense promoció realitzats a través
                                    del web, així com comunicar qualsevol confirmació, cancel·lació o incident en
                                    relació amb la comanda, i l'avís de la seva arribada a Sirvent Productes perquè el
                                    Client passi a recollir el seu encàrrec.</p>
                            </td>
                            <td className="width:198px;">
                                <p>Execució d’un contracte i consentiment de l’interessat</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="width:160px;">
                                <p><strong>Enviament de comunicacions comercials i/o promocionals en format
                                    electrònic</strong></p>
                            </td>
                            <td className="width:208px;">
                                <p>Enviament de comunicacions comercials de les activitats, promocions, publicitat,
                                    notícies, novetats de la web, newsletter i altra informació sobre els productes i
                                    serveis de Sirvent Productes a través de mitjans electrònics.</p>
                            </td>
                            <td className="width:198px;">
                                <p>Consentiment de l'interessat.</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p></p>
                    <p className="mt-12 mb-10"><strong>5. Durant quant de temps conservarem les seves dades?</strong></p>
                    <p></p>
                    <p>Les dades proporcionades pels nostres clients es conservaran mentre es mantingui la relació entre
                        les parts respectant en qualsevol cas els terminis mínims legals de conservació.</p>
                    <p>Les dades personals relatives a persones físiques vinculades a clients que Sirvent Productes
                        recopili mitjançant els diferents formularis de contacte i/o recollida d'informació es
                        conservaran mentre no se sol·liciti la seva supressió per l'interessat.</p>
                    <p>En qualsevol cas, Sirvent Productes guardarà les seves dades personals durant el període de temps
                        que sigui raonablement necessari per donar resposta a qüestions que es plantegin o resoldre
                        problemes, realitzar millores, activar nous serveis i complir els requisits que exigeixi la
                        legislació aplicable. Finalitzat el període d'utilitat de les dades, aquestes seran eliminades
                        dels sistemes de Sirvent Productes.</p>
                    <p className="mt-12 mb-10"><strong>6. Compartirem les seves dades amb tercers?</strong></p>
                    <p></p>
                    <p>Sirvent Productes no compartirà les seves dades personals amb cap tercer aliè a la relació entre
                        les parts que pretengui utilitzar-los en les seves accions de màrqueting directe, excepte en el
                        cas que disposem d'autorització expressa per a això.</p>
                    <p>En cas de cessió, serà prèviament informat de la mateixa, finalitat i identificació de
                        l'acessionari perquè pugui donar el seu consentiment exprés si fos necessari.</p>
                    <p>Sirvent Productes podrà cedir les seves dades a organismes de control, com l'Agència Espanyola
                        del Medicament o altres organismes de l'Administració Pública i autoritats competents quan es
                        rebi un requeriment legal per part de les autoritats esmentades o en els casos que es consideri
                        necessari en el marc d'un procediment judicial per protegir els drets de Sirvent Productes.</p>
                    <p>Sirvent Productes pot proporcionar les seves dades personals a terceres persones, com a
                        proveïdors de serveis. Aquestes terceres persones han de mantenir en tot moment els mateixos
                        nivells de control, seguretat i protecció de les dades personals que manté Sirvent
                        Productes.</p>
                    <p></p>
                    <p className="mt-12 mb-10"><strong>7. Quins són els seus drets quan ens facilita les seves dades?</strong></p>
                    <p className="margin-left:18.0pt;"></p>
                    <p>En la mesura que estiguin reconeguts en la normativa de protecció de dades aplicable en cada
                        moment, l'Usuari podrà exercir els següents drets en relació amb el tractament de les seves
                        dades:</p>
                    <p><strong>• Dret d'accés:</strong> L'Usuari podrà preguntar a Sirvent Productes si està tractant
                        les seves dades i, en cas afirmatiu, accedir-hi.</p>
                    <p><strong>• Dret de rectificació:</strong> L'Usuari podrà sol·licitar la rectificació de les dades
                        si aquestes són inexactes, o completar les dades que tinguem incomplertes.</p>
                    <p><strong>• Dret a sol·licitar la supressió de les seves dades</strong>: En el moment en què
                        l'Usuari exerceixi els seus drets de supressió, totes les dades personals vinculades al seu
                        compte, així com la informació i continguts que s'integrin en el seu perfil seran cancel·lades.
                    </p>
                    <p><strong>• Dret a sol·licitar la limitació del seu tractament:</strong> En aquest cas únicament
                        els conservarem per a l'exercici o la defensa de reclamacions.</p>
                    <p><strong>• Dret a oposar-se al tractament:</strong> Sirvent Productes deixarà de tractar les dades
                        personals, amb l'excepció que hagin de seguir tractant-se per motius legítims o per a l'exercici
                        o defensa de possibles reclamacions.</p>
                    <p>• <strong>Dret a la portabilitat de les dades:</strong> En cas que l'Usuari vulgui que les seves
                        dades siguin tractades per un altre responsable del tractament, Sirvent Productes li facilitarà
                        el transvasament de les seves dades al nou responsable.</p>
                    <p><strong>• Dret a no ser objecte d'una decisió basada únicament en el tractament automatitzat de
                        les seves dades personals.</strong></p>
                    <p>Per exercitar els seus drets, fins i tot per dur a terme una reclamació en cas que consideri que
                        Sirvent Productes està tractant de forma inadequada les seves dades, haurà d'enviar una
                        comunicació per escrit al domicili de Sirvent Productes o a l'adreça de correu electrònic <b><a
                            href="info@sirventproductes.com">info@sirventproductes.com</a></b>, incloent en ambdós
                        casos la fotocòpia del seu D.N.I., N.I.E. o qualsevol altre document d’identificació equivalent.
                    </p>
                    <p>Podeu utilitzar els models i formularis sobre els drets referits anteriorment dirigint-se a la
                        pàgina oficial de l'Agència Espanyola de Protecció de Dades (https://www.aepd.es/). Si vostè
                        considera que Sirvent Productes està tractant les seves dades personals de forma inadequada, pot
                        dirigir les seves reclamacions davant d'aquesta Autoritat de Control.</p>
                    <p className="mt-12 mb-10"><strong>8. Canvis en la Política de Privacitat</strong></p>
                    <p>Només utilitzarem dades de caràcter personal segons el que estableix la Política de Privacitat
                        que estigui en vigor en el moment en què es recaptin aquestes dades.</p>
                    <p>Sirvent Productes es reserva el dret a modificar en qualsevol moment aquesta Política de
                        Privacitat, publicant aquestes modificacions a la web, per la qual cosa es recomana visitar-la
                        cada vegada que s'accedeixi a la mateixa. Si en algun moment vam decidir utilitzar dades
                        personals de manera diferent al que es va declarar en el moment de la seva obtenció, es
                        comunicarà a l'Usuari per correu electrònic, sempre que disposem d'ell mateix. En aquest moment,
                        se li donarà l'opció d'autoritzar altres usos o divulgacions de les dades personals que ens
                        hagués facilitat abans de la modificació de la nostra Política de Privacitat.</p>
                    <p>En cas de que qualsevol clàusula de la present Política de Privacitat sigués anul·lada o
                        considerada nul·la, la resta de les condicions no es veuran afectades, conservant plenament la
                        seva validesa i vigència, en atenció a la normativa vigent d'aplicació en cada moment.</p>
                    <p className="mt-12 mb-10"><strong>9. Enllaços a altres llocs web</strong></p>
                    <p>Al nostre web s'utilitzen o es poden utilitzar hipervincles que permetin un enllaç amb altres
                        pàgines o llocs web. Sirvent Productes no es responsabilitza del contingut d'aquests llocs web
                        ni són responsables de les polítiques de privacitat dels mateixos. Es recomana tenir en compte i
                        llegir les polítiques de privacitat de tots els llocs web que visiteu. Recordeu que el que
                        s'indica en aquesta política de privacitat només regeix per a les dades que recull i/o tracta
                        Sirvent Productes.</p>
                    <p className="mt-12 mb-10"><strong>10. Legislació aplicable</strong></p>
                    <p>La privacitat de tota la informació facilitada, tant la facilitada per l'Usuari per mitjà dels
                        diferents formularis de sol·licitud de dades personals està regulada per la normativa de
                        protecció de dades vigent, en especial, per l'RGPD i per la LOPDGDD.</p>
                </div>
            </Box>
          </Container>
        </PrivacyArea>
  );
};

export default PrivacyPolicyPage;
